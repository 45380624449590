//@HACK - fonts don't work with storage.cloud.google.com
$staticStorageUrl: 'https://static.bit.dev';

/**
 * Define `@font-face` rules for font-family Circular Pro Book, including bold and italic.  
 * Provides a handy class name to add to root component.
 *
 * @example
 * import { bookFont } from '@bit/bit.base-ui.theme.fonts.book';
 * //...
 *
 * <body className={bookFont}>
 *   <div>I'm using font!</div>
 *   <div style={{ fontWeight: 'bold'}}>I am BOLD</div>
 * </body>
 */

// default
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-Book.otf');
}

// bold
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-Bold.otf');
	font-weight: bold;
}

// // bold-er
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-Black.otf');
	font-weight: 800;
}

// italic
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-BookItalic.otf');
	font-style: italic;
}

// bold + italic
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-BoldItalic.otf');
	font-style: italic;
	font-weight: bold;
}

// bold-er + italic
@font-face {
	font-family: "CircularPro";
	src: url($staticStorageUrl + '/fonts/CircularPro-BlackItalic.otf');
	font-style: italic;
	font-weight: 800;
}

.bookFont {
	font-family: "CircularPro", "Gill Sans", "Gill Sans MT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}